<template>
  <div>
    <v-text-field
      v-model="form.email"
      :error-messages="emailErrors"
      :label="$t('common|email')"
      required
      outlined
      @input="$v.form.email.$touch()"
      @blur="$v.form.email.$touch()"
    />
    <v-text-field
      v-model="form.password"
      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
      :error-messages="passwordErrors"
      :label="$t('common|password')"
      :type="showPass ? 'text' : 'password'"
      required
      outlined
      @input="$v.form.password.$touch()"
      @blur="$v.form.password.$touch()"
      @click:append="showPass = !showPass"
    />
    <v-checkbox
      v-model="form.betaCheckbox"
      :label="$t('expressions|beta_user_promo_key')"
      class="mt-n2"
    />
    <v-text-field
      v-if="form.betaCheckbox"
      v-model="form.betaKey"
      outlined
      required
      :label="$t('expressions|enter_beta_code_here')"
      :error-messages="betaKeyErrors"
      class="mb-4"
      @input="$v.form.betaKey.$touch()"
      @blur="$v.form.betaKey.$touch()"
    />
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, email, helpers } from 'vuelidate/lib/validators'
const alphaNum = helpers.regex('alphaNumAndDot', /^[a-z\d.!#$%&()*+,./=@[\]^_|~]*$/i)

export default {
  mixins: [validationMixin],
  validations: {
    form: {
      email: { required, email, alphaNum },
      password: { required, minLength: minLength(7), alphaNum },
      betaKey: { required, minLength: minLength(20) }
    }
  },
  props: {
    formData: {
      type: Object,
      default: () => ({
        accountType: 'lawyer',
        email: null,
        password: null,
        betaCheckbox: false,
        betaKey: null
      })
    }
  },
  data () {
    const form = this.formData
    return {
      form,
      showPass: false
    }
  },
  computed: {
    emailErrors () {
      const errors = []
      if (!this.$v.form.email.$dirty) return errors
      !this.$v.form.email.email && errors.push(this.$t('warning|valid_email'))
      !this.$v.form.email.required && errors.push(this.$t('warning|email_required'))
      !this.$v.form.email.alphaNum && errors.push('Your email-address contains one of the following forbidden special characters!')
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.form.password.$dirty) return errors
      !this.$v.form.password.required && errors.push(this.$t('warning|password_required'))
      !this.$v.form.password.minLength && errors.push(this.$t('warning|password_type'))
      !this.$v.form.password.alphaNum && errors.push(`Your password contains forbidden special characters: { } " - ? : ' < > ${'`'} `)
      return errors
    },
    betaKeyErrors () {
      const errors = []
      if (!this.$v.form.betaKey.$dirty) return errors
      !this.$v.form.betaKey.required && errors.push(this.$t('warning|beta_key_required'))
      !this.$v.form.betaKey.minLength && errors.push(this.$t('warning|beta_key_length'))
      return errors
    },
    betaCheckboxValue () {
      return this.form.betaCheckbox
    }
  },
  watch: {
    form: {
      handler (value) {
        if (
          !value.betaCheckbox &&
          value.email &&
          value.email.length &&
          value.password &&
          value.password.length
        ) this.$emit('enable-submit-btn')
        else if (
          value.betaCheckbox &&
          value.email &&
          value.email.length &&
          value.password &&
          value.password.length &&
          value.betaKey &&
          value.betaKey.length === 20
        ) this.$emit('enable-submit-btn')
        else this.$emit('disable-submit-btn')
      },
      deep: true
    },
    betaCheckboxValue (value) {
      if (!value) this.form.betaKey = null
    }
  },
  methods: {
    reset () {
      this.form = this.formData
      this.$nextTick(() => { this.$v.form.$reset() })
    },
    validate () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return false
      return true
    },
    getData () {
      return this.form
    }
  }
}
</script>
