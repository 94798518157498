<template>
  <div style="width: 100%; height: 100%;">
    <!-- MOBILE -->
    <v-container
      v-if="$vuetify.breakpoint.mobile"
      fill-height
      class="align-center justify-center"
      style="overflow-y: auto; background-color: white"
    >
      <form
        class="pa-5 rounded-lg"
      >
        <div class="d-flex align-center justify-center pt-4 pb-4">
          <img
            src="@/assets/Lavirra_logo_SVG.svg"
            height="60px"
            width="auto"
          >
        </div>
        <v-row>
          <h3
            class="mx-auto my-6"
            style="color: #757575;"
          >
            {{ $t('actions|create_account') }}
          </h3>
        </v-row>
        <v-select
          ref="accountTypeField"
          v-model="accountType"
          :items="accountTypes"
          item-text="text"
          item-value="value"
          :label="$t('actions|choose_account_type')"
          outlined
          :menu-props="{ auto: true, overflowY: true }"
        >
          <template v-slot:item="slotProps">
            <div class="my-2">
              {{ slotProps.item.text }}
            </div>
          </template>
        </v-select>
        <v-select
          v-if="accountType === 'Client'"
          v-model="clientAccountType"
          :items="clientAccountTypes"
          item-text="text"
          item-value="value"
          :label="$t('actions|select')"
          outlined
          required
          :rules="selectorRules"
        >
          <template v-slot:item="slotProps">
            <div class="my-2">
              {{ slotProps.item.text }}
            </div>
          </template>
        </v-select>
        <LawyerRegisterForm
          v-if="accountType === 'LawyerAccount'"
          ref="form"
          @enable-submit-btn="disabledBtn = false"
          @disable-submit-btn="disabledBtn = true"
        />
        <CompanyRegisterForm
          v-if="accountType !== 'LawyerAccount' && clientAccountType === 'CompanyAccount'"
          ref="form"
          @enable-submit-btn="disabledBtn = false"
          @disable-submit-btn="disabledBtn = true"
        />
        <IndividualRegisterForm
          v-if="accountType !== 'LawyerAccount' && clientAccountType === 'IndividualAccount'"
          ref="form"
          @enable-submit-btn="disabledBtn = false"
          @disable-submit-btn="disabledBtn = true"
        />
        <div
          v-if="accountType"
          class="d-flex justify-center align-center"
        >
          <v-btn
            color="primary"
            class="mt-4"
            large
            :disabled="disabledBtn"
            min-width="300"
            @click="submit"
          >
            {{ $t('profile|register') }}
          </v-btn>
        </div>
        <div class="d-flex justify-center align-center mt-5">
          <router-link to="/login">
            {{ $t('profile|log_in') }}
          </router-link>
        </div>
        <v-overlay
          absolute
          :value="loading"
        >
          <v-progress-circular
            :size="50"
            color="white"
            indeterminate
          />
        </v-overlay>
      </form>
    </v-container>

    <!-- DESKTOP -->
    <v-container
      v-else
      fill-height
      class="align-center justify-center"
      style="overflow-y: auto;"
    >
      <form
        class="elevation-3 pa-10 rounded-lg"
      >
        <div class="d-flex align-center justify-center pt-4 pb-4">
          <img
            src="@/assets/Lavirra_logo_SVG.svg"
            height="60px"
            width="auto"
          >
        </div>
        <v-row>
          <h3
            class="mx-auto my-6"
            style="color: #757575;"
          >
            {{ $t('actions|create_account') }}
          </h3>
        </v-row>
        <v-select
          ref="accountTypeField"
          v-model="accountType"
          :items="accountTypes"
          item-text="text"
          item-value="value"
          :label="$t('actions|choose_account_type')"
          outlined
          :menu-props="{ auto: true, overflowY: true }"
        />
        <v-select
          v-if="accountType === 'Client'"
          v-model="clientAccountType"
          :items="clientAccountTypes"
          item-text="text"
          item-value="value"
          :label="$t('actions|select')"
          outlined
          required
          :rules="selectorRules"
        />
        <LawyerRegisterForm
          v-if="accountType === 'LawyerAccount'"
          ref="form"
          @enable-submit-btn="disabledBtn = false"
          @disable-submit-btn="disabledBtn = true"
        />
        <CompanyRegisterForm
          v-if="accountType !== 'LawyerAccount' && clientAccountType === 'CompanyAccount'"
          ref="form"
          @enable-submit-btn="disabledBtn = false"
          @disable-submit-btn="disabledBtn = true"
        />
        <IndividualRegisterForm
          v-if="accountType !== 'LawyerAccount' && clientAccountType === 'IndividualAccount'"
          ref="form"
          @enable-submit-btn="disabledBtn = false"
          @disable-submit-btn="disabledBtn = true"
        />
        <div
          v-if="accountType"
          class="d-flex justify-center align-center"
        >
          <v-btn
            color="primary"
            class="mt-4"
            large
            :disabled="disabledBtn"
            min-width="300"
            @click="submit"
          >
            {{ $t('profile|register') }}
          </v-btn>
        </div>
        <div class="d-flex justify-center align-center mt-5">
          <router-link to="/login">
            {{ $t('profile|log_in') }}
          </router-link>
        </div>
        <v-overlay
          absolute
          :value="loading"
        >
          <v-progress-circular
            :size="50"
            color="white"
            indeterminate
          />
        </v-overlay>
      </form>
    </v-container>
  </div>
</template>

<script>
import LawyerRegisterForm from '@/components/forms/LawyerRegisterForm.vue'
import CompanyRegisterForm from '@/components/forms/CompanyRegisterForm.vue'
import IndividualRegisterForm from '@/components/forms/IndividualRegisterForm.vue'
import store from '@/store/index'
import { mapActions } from 'vuex'

export default {
  components: {
    IndividualRegisterForm,
    CompanyRegisterForm,
    LawyerRegisterForm
  },
  data () {
    return {
      accountType: null,
      clientAccountType: null,
      accountTypes: [
        {
          value: 'LawyerAccount',
          text: this.$t('account|create_lawyer')
        },
        {
          value: 'Client',
          text: this.$t('account|create_client')
        }
      ],
      clientAccountTypes: [
        {
          value: 'CompanyAccount',
          text: this.$t('account|create_company')
        },
        {
          value: 'IndividualAccount',
          text: this.$t('account|create_individual')
        }
      ],
      selectorRules: [
        (v) => !!v || this.$t('warning|type_required')
      ],
      loading: false,
      error: null,
      disabledBtn: true
    }
  },
  watch: {
    accountType (val) {
      if (!val) this.reset()
    },
    clientAccountType (val) {
      if (!val) this.reset()
    }
  },
  created () {
    const language = window.navigator.userLanguage || window.navigator.language
    const eng = language.indexOf('en')
    const de = language.indexOf('de')
    const fr = language.indexOf('fr')
    const bg = language.indexOf('bg')
    const es = language.indexOf('es')
    const it = language.indexOf('it')
    const ro = language.indexOf('ro')
    if (eng !== -1) this.$i18n.locale = 'en'
    if (de !== -1) this.$i18n.locale = 'de'
    if (fr !== -1) this.$i18n.locale = 'fr'
    if (bg !== -1) this.$i18n.locale = 'bg'
    if (es !== -1) this.$i18n.locale = 'es'
    if (it !== -1) this.$i18n.locale = 'it'
    if (ro !== -1) this.$i18n.locale = 'ro'
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast'
    }),
    reset () {
      this.accountType = null
      this.$refs.accountTypeField.blur()
      this.clientAccountType = null
      this.loading = false
      this.error = null
    },
    async submit () {
      console.log('LOG CREATE', this.$refs.form)
      // if (!this.$refs.form.validate()) return
      this.loading = true
      try {
        const formData = this.$refs.form.getData()
        const { data } = await this.$axios.post('/createAccount', formData)
        if (!data.success) {
          this.error = this.$t('warning|registration_not_successful')
          return
        }
        this.error = null
        this.$router.push({ path: '/login' })
        this.addToast({
          title: this.$t('warning|registration_successful'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        console.error(e, 'ERROR')
        this.error = e
        if (e.response && e.response.data && e.response.data.msg && e.response.data.msg === 'Key not found') {
          this.addToast({
            title: this.$t('warning|key_is_not_valid_enter_valid_key'),
            color: 'white',
            snackbarColor: 'error'
          })
        } else {
          this.addToast({
            title: this.$t('warning|registration_not_successful'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      } finally {
        this.loading = false
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('auth/removeToken')
    store.dispatch('account/resetAccount')
    next()
  }
}
</script>

<style lang="scss" scoped>
  form {
    position: relative;
    width: 100%;
    max-width: 600px;
    background-color: rgb(255, 255, 255);
  }
</style>
